import React from 'react'
// Components
import { Primary as PrimaryButton } from '..'
// Context
import { usePrismic } from '../../context/PrismicContext'
// Styles
import { Container } from '../../styles/LoginPage.styles'
import { CardTitle, DataError, UnderlinedContainer } from './CreditCard.styles'
// Types
import { CreditCard } from '../../types/PaymentsTypes'
// Utils
import { getMMyyyyFormat } from '../../utils/dateHelpers'

type CreditCardProps = {
  creditCard: CreditCard
  selectedCardId: string
  setSelectedCardId: (s: string) => void
}

const CreditCardComponent = ({
  creditCard,
  selectedCardId,
  setSelectedCardId,
}: CreditCardProps) => {
  const {
    prismicData: {
      prismicPaymentInfoPage: { card_missing_error, expires, use_this_payment },
    },
  } = usePrismic()

  const { lastFourDigits, creditCardGuid, creditCardType, expirationDate } =
    creditCard

  if (
    !lastFourDigits ||
    !creditCardGuid ||
    !creditCardType ||
    !expirationDate
  ) {
    return (
      <UnderlinedContainer>
        <DataError>{card_missing_error[0].text}</DataError>
      </UnderlinedContainer>
    )
  }

  const handleChanged: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    const selectedCardId = target.value
    setSelectedCardId(selectedCardId)
  }

  const isSelected = selectedCardId === creditCardGuid
  return (
    <UnderlinedContainer>
      <Container key={lastFourDigits}>
        <input
          checked={isSelected}
          data-qa={`radioButton-${lastFourDigits}`}
          id={creditCardGuid}
          name="creditCard"
          onChange={handleChanged}
          type="radio"
          value={creditCardGuid}
        />
        <Container direction="column" align="start" padding="0 40px">
          <CardTitle
            data-qa={`cardTitle-${lastFourDigits}`}
          >{`${creditCardType} **** ${lastFourDigits}`}</CardTitle>
          <span>{`${expires[0].text} ${getMMyyyyFormat(expirationDate)}`}</span>
        </Container>
      </Container>
      <Container justify="start" padding="0 .5em">
        <PrimaryButton
          style={{
            width: '60%',
            margin: '0',
            display: isSelected ? 'flex' : 'none',
          }}
          type="submit"
          data-qa={`usePaymentButton-${lastFourDigits}`}
        >
          {use_this_payment[0].text}
        </PrimaryButton>
      </Container>
    </UnderlinedContainer>
  )
}

export default CreditCardComponent
